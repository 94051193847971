<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="(item, index) in breadcrumbData"
        :key="item.path"
      >
        <!-- 不可点击 -->
        <span class="no-redirect" v-if="index === breadcrumbData.length - 1">
          {{ item.meta.title }}
        </span>

        <!-- 可以点击 -->
        <span class="redirect" @click.prevent="onLinkClick(item)" v-else>{{
          item.meta.title
        }}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
// 1： 动态数据
// 2： 渲染 el-breadcrumb-item

import { watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { userStore } from '@/store/index'
// 创建一个方法，用来生成数组数据，在这里我们要使用到 route.match 属性来：获取与给定路由地址匹配的标准化的路由记录数组
// 生成数组数据
const breadcrumbData = ref([])
const route = useRoute()

const getBreadcrumbData = () => {
  // route.matched
  // 当前路由的路由表

  breadcrumbData.value = route.matched.filter(
    (item) => item.meta && item.meta.title
  )
}

watch(
  route,
  () => {
    getBreadcrumbData()
  },
  {
    immediate: true
  }
)

// 点击面包屑
const router = useRouter()
const onLinkClick = (item) => {
  router.push(item.path)
}

// hover 颜色
const store = userStore()
const linkHoverColor = ref(store.cssVar.menuBg)

console.log(linkHoverColor) // 防止没有使用报错
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  ::v-deep .no-redirect {
    color: #97a8be;
    cursor: text;
  }

  // 可以点击
  .redirect {
    color: #666;
    font-weight: 600;
    cursor: pointer;
  }

  .redirect:hover {
    // 将来需要进行主题替换，所以这里不去写死样式
    color: v-bind(linkHoverColor);
  }
}
</style>
