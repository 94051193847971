import { defineStore } from 'pinia'
import { login } from '@/api/sys'
import { getItem, setItem, removeAllItem } from '../utils/storage'
import { setTimeStamp } from '@/utils/auth'
import { TOKEN, USER_ID } from '@/constant'
import router, { resetRouter } from '../router/index'
import variables from '../style/variables.scss'

export const userStore = defineStore('user', {
  state: () => {
    return {
      token: getItem(TOKEN) || '',
      userid: getItem(USER_ID) || '',
      cssVar: variables
    }
  },
  getters: {
    //
  },
  actions: {
    // 登录请求动作
    login({ username, password }) {
      return new Promise((resolve, reject) => {
        login({
          username,
          password
        })
          .then((data) => {
            // 保存 token
            this.token = data.token
            this.userid = data.id
            setItem(TOKEN, data.token)
            setItem(USER_ID, data.id)
            // 保存登录时间
            setTimeStamp()
            // 使用路由跳转
            router.push('/')
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 退出登录
    logout() {
      // TODO 1：清空 pinia 数据
      this.token = ''
      this.permission = ''
      // TODO 2：清空 本地数据
      removeAllItem()

      // TODO 3: 清空权限 配置
      resetRouter()

      // TODO 4: 返回登录页
      router.push('/login')
    }
  }
})
