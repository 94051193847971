// financeRefund
// 退费申请新
import layout from '@/layout/index.vue'
export default {
  path: '/finance',
  component: layout,
  name: 'financeRefundA',
  redirect: '/finance/renewal',
  meta: {
    title: '财务管理',
    icon: 'caiwu'
  },
  children: [
    {
      path: '/finance/refundA',
      component: () => import('@/views/refundA/index.vue'),
      meta: {
        title: '退费申请-新',
        icon: 'shenqing'
      }
    },
    {
      path: '/finance/print/:id?',
      name: 'financePrint',
      component: () => import('@/views/refundA/components/print.vue'),
      meta: {
        title: '打印退费信息'
      }
    }
  ]
}
