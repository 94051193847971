// financePayTheFees
// 缴费设置
import layout from '@/layout/index.vue'
export default {
  path: '/finance',
  component: layout,
  name: 'financePayTheFees',
  redirect: '/finance/renewal',
  meta: {
    title: '财务管理',
    icon: 'caiwu'
  },
  children: [
    {
      path: '/finance/payTheFees',
      component: () => import('@/views/pay-the-fees/index.vue'),
      meta: {
        title: '缴费设置',
        icon: 'shezhi'
      }
    },
    {
      path: '/finance/addFees/',
      name: 'financeAddFees',
      component: () => import('@/views/pay-the-fees/components/addFees.vue'),
      meta: {
        title: '添加缴费设置'
      }
    }
  ]
}
