// specialized
// 专业设置
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'specialized',
  children: [
    {
      path: '/specialized',
      component: () => import('@/views/specialized/index.vue'),
      meta: {
        title: '专业设置',
        icon: 'zhuanye'
      }
    }
  ]
}
