import layout from '@/layout/index.vue'
export default {
  path: '/signUp',
  component: layout,
  // name: 'signUp',
  name: 'signUpAudited',
  redirect: '/signUp/toBeReviewed',
  meta: {
    title: '报名信息',
    icon: 'baoming'
  },
  children: [
    {
      path: '/signUp/audited',
      // name: 'signUpAudited',
      component: () => import('@/views/audited/index.vue'),
      meta: {
        title: '已审核',
        icon: 'yi_shen_he'
      }
    },
    {
      path: '/signUp/reviseInformation/:id',
      name: '/signUpReviseInformation',
      component: () => import('@/views/audited/reviseInformation.vue'),
      meta: {
        title: '修改报名信息'
      }
    },
    {
      path: '/signUp/print/:img',
      name: '/signUpPrint',
      component: () => import('@/views/print/index.vue'),
      meta: {
        title: '打印通知书'
      }
    }
  ]
}
