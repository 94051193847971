import { defineStore } from 'pinia'
import { permissionAPI } from '@/api/permission'

export const menuSrore = defineStore('menuSrore', {
  state: () => {
    return {
      permission: {}
    }
  },
  actions: {
    async onPermission(id) {
      const res = await permissionAPI({ id })
      this.permission = res
      return res
    }
  }
})

export const a = menuSrore.permission
