// level
// 层次管理
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'level',
  children: [
    {
      path: '/level',
      component: () => import('@/views/level/index.vue'),
      meta: {
        title: '层次管理',
        icon: 'cengci'
      }
    }
  ]
}
