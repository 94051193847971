// financePayTheFees
// 缴费设置
import layout from '@/layout/index.vue'
export default {
  path: '/finance',
  component: layout,
  name: 'socialPayment',
  redirect: '/finance/renewal',
  meta: {
    title: '财务管理',
    icon: 'caiwu'
  },
  children: [
    {
      path: '/finance/socialPayment',
      component: () => import('@/views/social-payment/index.vue'),
      meta: {
        title: '社会缴费',
        icon: 'shezhi'
      }
    },
    {
      path: '/finance/detail/:id/:number',
      name: 'financeDetail',
      component: () => import('@/views/social-payment/components/detail.vue'),
      meta: {
        title: '社会缴费详情'
      }
    },
    {
      path: '/finance/import/:number',
      name: 'financeImport',
      component: () => import('@/views/social-payment/import/index.vue'),
      meta: {
        title: '社会缴费详情导入'
      }
    }
  ]
}

// socialPayment
// detail
