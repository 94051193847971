// ! 登录鉴权
import router from '@/router'
import { userStore } from '@/store'
import { permissionStore } from '@/store/permission'
import { menuSrore } from '@/store/menu'
// 白名单
const whiteList = ['/login']

// TODO 路由前置守卫
// to 到哪里去
// from 从哪里来
// nex 跳转页面
router.beforeEach(async (to, from, next) => {
  const store = userStore()
  if (store.token) {
    // TODO 用户已经登录不允许进入 login
    if (to.path === '/login') {
      next('/')
    } else {
      const MSrore = menuSrore()
      if (JSON.stringify(MSrore.permission) === '{}') {
        const { menus } = await MSrore.onPermission(store.userid)
        // console.log(menus)
        const PStore = permissionStore()
        // 1 : 得到用户权限数据
        const fRoutes = PStore.filterRoutes(menus)
        // 2: 循环添加动态路由
        // 利用 addRoute 循环添加
        fRoutes.forEach((item) => {
          router.addRoute(item)
        })
        // 添加完动态路由之后，需要在进行一次主动跳转
        return next(to.path)
      }
      next()
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
