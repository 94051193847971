// financeRefund
// 退费申请
import layout from '@/layout/index.vue'
export default {
  path: '/finance',
  component: layout,
  name: 'financeStudentPayment',
  redirect: '/finance/renewal',
  meta: {
    title: '财务管理',
    icon: 'caiwu'
  },
  children: [
    {
      path: '/finance/studentPayment',
      component: () => import('@/views/studentPayment/index.vue'),
      meta: {
        title: '学生缴费汇总',
        icon: 'shenqing'
      }
    }
    //  Excel 导入
    // {
    //   path: '/graduatePay/import',
    //   name: 'graduatePayImport',
    //   component: () => import('@/views/graduatePay/import/index'),
    //   meta: {
    //     title: 'excel导入'
    //   }
    // }
  ]
}
