// 待审核
// 按钮：处理
// signUpToBeReviewed
import layout from '@/layout/index.vue'
export default {
  path: '/signUp',
  component: layout,
  name: 'signUpToBeReviewed',
  redirect: '/signUp/toBeReviewed',
  meta: {
    title: '报名信息',
    icon: 'baoming'
  },
  children: [
    {
      path: '/signUp/toBeReviewed',
      // name: 'signUpToBeReviewed',
      component: () => import('@/views/to-be-reviewed/index'),
      meta: {
        title: '待审核',
        icon: 'dai_sheng_he'
      }
    },
    {
      path: '/signUp/dealWith/:id',
      name: '/signUpDealWith',
      component: () => import('@/views/to-be-reviewed/dealWith.vue'),
      meta: {
        title: '处理'
      }
    }
  ]
}
