import { publicRoutes, privateRoutes } from '@/router'
import { defineStore } from 'pinia'
export const permissionStore = defineStore('permission', {
  state: () => {
    return {
      // 路由表：初始拥有静态路由权限
      routes: publicRoutes
    }
  },
  actions: {
    /**
     * 增加路由
     */
    setRoutes(newRoutes) {
      // 永远在静态路由的基础上增加新路由
      this.routes = [...publicRoutes, ...newRoutes]
    },

    // 根据权限数据筛选路由
    filterRoutes(menus) {
      //  menus 权限数据
      const routes = [] // 需要追加的路由数据

      menus.forEach((key) => {
        // 权限名 与 路由的 name 匹配
        routes.push(
          ...privateRoutes.filter((item) => {
            return item.name === key
          })
        )
      })
      // 最后添加 不匹配路由进入 404
      routes.push({
        path: '/:catchAll(.*)',
        redirect: '/404'
      })
      this.setRoutes(routes)
      return routes
    }
  }
})
