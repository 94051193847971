<template>
  <!-- 一级 menu 菜单 -->
  <!--  :uniqueOpened="true" 只允许打开一个 -->
  <!--  collapse 动画折叠效果 -->
  <el-menu
    :default-active="activeMenu"
    :collapse="!apptore.sidebarOpened"
    :background-color="store.cssVar.menuBg"
    :text-color="store.cssVar.menuText"
    :active-text-color="store.cssVar.subMenuActiveText"
    :unique-opened="true"
    @select="handleselect"
    router
  >
    <sidebar-item
      v-for="item in routes"
      :key="item.path"
      :route="item"
    ></sidebar-item>
  </el-menu>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { computed, inject } from 'vue'
import { filterRouters, generateMenus } from '@/utils/router'
import SidebarItem from './SidebarItem.vue'
import { userStore } from '../../../store/index'
import { appStore } from '@/store/app'

const router = useRouter()
// router.getRoutes() 获取全部的路由记录，需要进行处理
const routes = computed(() => {
  const filterRoutes = filterRouters(router.getRoutes())
  return generateMenus(filterRoutes)
})

// store
const store = userStore()
const apptore = appStore()
// 默认激活
const route = useRoute()
const activeMenu = computed(() => {
  const { path } = route
  return path
})

const reload = inject('reload')
const handleselect = () => {
  reload()
}
</script>

<style lang="scss" scoped>
::v-deep .el-menu-item.is-active {
  background: #0086ed;
}
</style>
