// candidate
// 老生信息
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'studentsdb',
  children: [
    {
      path: '/studentsdb',
      name: 'studentsdb',
      component: () => import('@/views/studentsdb/index.vue'),
      meta: {
        title: '在校学生数据库',
        icon: 'laosheng'
      }
    },
    //  Excel 导入
    {
      path: '/studentsdb/import',
      name: 'studentsdbImport',
      component: () => import('@/views/studentsdb/import/index'),
      meta: {
        title: 'excel导入'
      }
    }
  ]
}
