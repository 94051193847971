// candidate
// 老生信息
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'candidate',
  children: [
    {
      path: '/candidate',
      name: 'candidate',
      component: () => import('@/views/candidate/index.vue'),
      meta: {
        title: '老生信息',
        icon: 'laosheng'
      }
    },
    //  Excel 导入
    {
      path: '/candidate/import',
      name: 'candidateImport',
      component: () => import('@/views/import/index'),
      meta: {
        title: 'excel导入'
      }
    }
  ]
}
