import request from '@/utils/request'

// TODO 登录
export const login = (data) => {
  return request({
    url: '/login',
    method: 'POST',
    data
  })
}

//  修改密码
export const revisePasswordAPI = (data) => {
  return request({
    url: '/userPassword',
    method: 'POST',
    data
  })
}
