<template>
  <el-config-provider :locale="locale">
    <!-- <router-view /> -->
    <router-view v-if="isRouterAlive" />
  </el-config-provider>
</template>

<script setup>
import { computed, provide, ref, nextTick } from 'vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import { appStore } from '@/store/app'
const AStore = appStore()
// console.log(AStore.language)
// const locale = en

const locale = computed(() => {
  return AStore.language === 'zh' ? zhCn : en
})

const isRouterAlive = ref(true)

const reload = async () => {
  isRouterAlive.value = false
  await nextTick()
  isRouterAlive.value = true
}

provide('reload', reload)
</script>

<style></style>
