// organizationAuthority
// 权限列表
import layout from '@/layout/index.vue'
export default {
  path: '/organization',
  component: layout,
  name: 'organizationAuthority',
  redirect: '/organization/role',
  meta: {
    title: '组织架构',
    icon: 'zuzhi'
  },
  children: [
    {
      path: '/organization/authority',
      name: 'organizationAuthority',
      component: () => import('@/views/organization/authority/index.vue'),
      meta: {
        title: '权限列表',
        icon: 'zuzhi'
      }
    }
  ]
}
