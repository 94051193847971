// article
// 文章管理
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'classification',
  children: [
    {
      path: '/classification',
      component: () => import('@/views/classification/index.vue'),
      meta: {
        title: '分类管理',
        icon: 'fenlei'
      }
    }
  ]
}
