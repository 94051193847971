// financeOnProject
// 缴费项目
import layout from '@/layout/index.vue'
export default {
  path: '/finance',
  component: layout,
  name: 'financeOnProject',
  redirect: '/finance/renewal',
  meta: {
    title: '财务管理',
    icon: 'caiwu'
  },
  children: [
    {
      path: '/finance/onproject',
      component: () => import('@/views/project/index.vue'),
      meta: {
        title: '缴费项目',
        icon: 'jiaofe'
      }
    },
    {
      path: '/finance/addFees/',
      name: 'financeAddFees',
      component: () => import('@/views/pay-the-fees/components/addFees.vue'),
      meta: {
        title: '添加缴费设置'
      }
    }
  ]
}
