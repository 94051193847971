// financeRefund
// 退费申请
import layout from '@/layout/index.vue'
export default {
  path: '/finance',
  component: layout,
  name: 'financeGraduatePay',
  redirect: '/finance/renewal',
  meta: {
    title: '财务管理',
    icon: 'caiwu'
  },
  children: [
    {
      path: '/finance/graduatePay',
      component: () => import('@/views/graduatePay/index.vue'),
      meta: {
        title: '毕业生缴费',
        icon: 'shenqing'
      }
    },
    //  Excel 导入
    {
      path: '/graduatePay/import',
      name: 'graduatePayImport',
      component: () => import('@/views/graduatePay/import/index'),
      meta: {
        title: 'excel导入'
      }
    }
  ]
}
