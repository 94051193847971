import { createApp } from 'vue'
// import { createPinia } from 'pinia'
import pinia from './store/store'
import App from './App.vue'
// 引入 router
import router from './router'
// 导入 element-plus
import installElementPlus from './plugins/element'

// 引入 icons
import installIcons from '@/icons'

// 导入 i18n
import i18n from '@/i18n'

// 全局样式
import '@/style/index.scss'
// 导入权限控制模块
import './permission'

// 全局注册
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 指令
import installDirective from '@/directives'

// const pinia = createPinia()

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
installElementPlus(app)
installIcons(app)
installDirective(app)
app.use(pinia)
app.use(router)
app.use(i18n)
app.mount('#app')
