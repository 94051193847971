// article
// 文章管理
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'article',
  children: [
    {
      path: '/article',
      component: () => import('@/views/article/index.vue'),
      meta: {
        title: '文章管理',
        icon: 'wenzhang'
      }
    },
    // 这里的文章添加不应该在这里写：💛
    {
      path: '/article/addEdit/:id?',
      name: 'articleAddEdit',
      component: () => import('@/views/article/addEdit.vue'),
      meta: {
        title: '添加文章'
      }
    }
  ]
}
