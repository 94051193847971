import { createRouter, createWebHashHistory } from 'vue-router'
import { menuSrore } from '@/store/menu'
import pinia from '@/store/store'
import layout from '@/layout/index.vue'
import signUpAudited from './modules/signUpAudited'
import signUpToBeReviewed from './modules/signUpToBeReviewed'
import organizationRole from './modules/organizationRole'
import organizationStaff from './modules/organizationStaff'
import organizationAuthority from './modules/organizationAuthority'
import operate from './modules/operate'
import financeRenewal from './modules/financeRenewal'
import financeRenewalWX from './modules/financeRenewalWX'
import financeThing from './modules/financeThing'
import financeRefund from './modules/financeRefund'
import financeRefundA from './modules/financeRefundA'
import financePayTheFees from './modules/financePayTheFees'
import financeOnProject from './modules/financeOnProject'
import aboutUs from './modules/aboutUs'
import candidate from './modules/candidate'
import level from './modules/level'
import specialized from './modules/specialized'
import colleges from './modules/colleges'
import article from './modules/article'
import classification from './modules/classification'
import financeSocialPayment from './modules/financeSocialPayment'
import financeInfirmary from './modules/financeInfirmary'
import studentsdb from './modules/studentsdb'
import financeGraduatePay from './modules/financeGraduatePay'
import financeStudentPayment from './modules/financeStudentPayment'

// 公开路由表
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/',
    component: layout,
    redirect: '/rotation',
    children: [
      // 轮播管理
      {
        path: '/rotation',

        name: 'rotation',
        component: () => import('@/views/rotation/index.vue'),
        meta: {
          title: '轮播管理',
          icon: 'lunbo'
        }
      },

      // 404
      {
        path: '/404',
        name: '/404',
        component: () => import('@/views/error-page/404.vue')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/404.vue')
      }
    ]
  }
]

// 私有路由表
export const privateRoutes = [
  classification,
  article,
  colleges,
  specialized,
  level,
  candidate,
  aboutUs,
  operate,
  organizationRole,
  organizationStaff,
  organizationAuthority,
  signUpAudited,
  signUpToBeReviewed,
  financeRenewal,
  financeRenewalWX,
  financeThing,
  financeRefund,
  financeRefundA,
  financePayTheFees,
  financeOnProject,
  financeSocialPayment,
  financeInfirmary,
  studentsdb,
  financeGraduatePay,
  financeStudentPayment
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
})
const MSrore = menuSrore(pinia)
export function resetRouter() {
  if (MSrore.permission) {
    const { menus } = MSrore.permission
    if (Array.isArray(menus)) {
      menus.forEach((menu) => {
        router.removeRoute(menu)
      })
    }

    // 退出时还需要将 pinia 中的数据置空
    MSrore.permission = {}
  }
}
export default router
