<template>
  <el-dropdown
    trigger="click"
    class="international"
    @command="handleSetLanguage"
  >
    <div>
      <el-tooltip content="国际化">
        <svg-icon icon="language" />
      </el-tooltip>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item :disabled="language === 'zh'" command="zh">
          中文
        </el-dropdown-item>
        <el-dropdown-item :disabled="language === 'en'" command="en">
          English
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
// TODO 引入 i18n
import { useI18n } from 'vue-i18n'

import { computed } from 'vue'
import { appStore } from '@/store/app'
import { ElMessage } from 'element-plus'

const appstore = appStore()
const language = computed(() => appstore.language)

// TODO 切换语言的方法
// 得到 i18n 的实例
const i18n = useI18n()
const handleSetLanguage = (lang) => {
  // 修改 i18n 的数据
  i18n.locale.value = lang
  // 修改 pinia 的数据
  appstore.setLanguage(lang)
  // 提示数据修改
  ElMessage.success('更新成功')
}
</script>
