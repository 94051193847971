// financeThing
// 物品发放
import layout from '@/layout/index.vue'
export default {
  path: '/finance',
  component: layout,
  name: 'financeThing',
  redirect: '/finance/renewal',
  meta: {
    title: '财务管理',
    icon: 'caiwu'
  },
  children: [
    {
      path: '/finance/thing',
      name: 'financeThing',
      component: () => import('@/views/thing/index.vue'),
      meta: {
        title: '物品发放',
        icon: 'fafang'
      }
    }
  ]
}
