import { defineStore } from 'pinia'
import { setItem, getItem } from '@/utils/storage'
import { LANG } from '@/constant/index'
export const appStore = defineStore('app', {
  state: () => {
    return {
      sidebarOpened: true,
      // 国际化
      language: getItem(LANG) || 'zh'
    }
  },
  getters: {
    // 函数（state）
  },
  actions: {
    // 函数
    triggerSidebarOpened() {
      this.sidebarOpened = !this.sidebarOpened
    },

    // 国际化数据
    setLanguage(lang) {
      setItem(LANG, lang)
      this.language = lang
    }
  }
})
