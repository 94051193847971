// ? 时效 token 解决方案

/**
    1：在用户登陆时，记录当前 登录时间
    2：制定一个 失效时长
    3：在接口调用时，根据 当前时间 对比 登录时间 ，看是否超过了 时效时长
        3.1：如果未超过，则正常进行后续操作
        3.2：如果超过，则进行 退出登录 操作
 */

import { TIME_STAMP, TOKEN_TIMEOUT_VALUE } from '@/constant'
import { setItem, getItem } from '@/utils/storage'

// TODO 设置时间戳
// TODO  Date.now() 当前时间戳
export function setTimeStamp() {
  setItem(TIME_STAMP, Date.now())
}

// TODO 获取时间戳
export function getTimeStamp() {
  return getItem(TIME_STAMP)
}

// TODO 是否超时
export function isCheckTimeout() {
  // 当前时间戳
  var currentTime = Date.now()

  // 缓存时间戳
  var timeStamp = getTimeStamp()

  return currentTime - timeStamp > TOKEN_TIMEOUT_VALUE
}

// 拷贝对象
// desc: 从一个复杂的对象中拿出自己只需要的值
export const replaceObj = (obj1, obj2) => {
  for (const key in obj1) {
    obj1[key] =
      obj2[key] !== null || obj2[key] !== undefined ? obj2[key] : obj1[key]
  }
  return obj1
}
